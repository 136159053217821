.property-info-container {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 2px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .property-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .property-info h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .property-info-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    padding-right: 20px;
  }
  
  .property-info-left p {
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .property-info-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  
  .property-info-right img {
    max-height: 200px;
    max-width: 100%;
    object-fit: contain;
  }
  
  .marketplace-link {
    margin-top: 10px;
    text-align: center;
  }
  
  .offers {
    margin-top: 30px;
    width: 100%;
  }
  
  .details-view-content {
    margin-top: 20px;
  }
  
  .details-view-content h3 {
    margin-top: 0;
  }
  
  .details-view-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .details-view-table th {
    background-color: #f5f5f5;
    text-align: left;
    padding: 5px 10px;
    font-weight: normal;
  }
  
  .details-view-table td {
    padding: 5px 10px;
  }
  
  .details-view-table tr.even {
    background-color: #f5f5f5;
  }
  
  .details-view-table tr.odd {
    background-color: #ffffff;
  }
  