/* AllOffersView.css */
.all-offers-view-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .all-offers-view-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .all-offers-view-table thead tr {
    background-color: #f2f2f2;
  }
  
  .all-offers-view-table th,
  .all-offers-view-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .all-offers-view-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .all-offers-view-table tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  