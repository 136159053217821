/* MarketExplorerView.css */

.market-explorer-view {
    width: 50%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 1rem;
  }
  
  .search-box {
    font-size: 1.5em;
    padding: 0.5em;
    width: 98%;
    margin-bottom: 1rem;
  }
  
  .properties-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .property-card {
    width: calc(100% / 6 - 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f8f8f8;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: 1rem;
    transition: all 0.2s;
  }
  
  .property-card:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
    background-color: #f0f0f0;
  }
  
  .property-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 0.5rem;
  }
  
  .property-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .property-details > div {
    margin-bottom: 0.25rem;
  }
  
  .property-details > div:first-child {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }