.offers-table {
  width: 50%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.offers-table th,
.offers-table td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.offers-table thead .header-row {
  background-color: #f2f2f2;
  border-bottom: 2px solid #e0e0e0;
}

.offers-table tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}

.offers-table tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer;
}

.offers-table input[type='text'] {
  width: 100%;
  max-width: 60px;
  box-sizing: border-box;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
}
.time-column {
  width: 100px; /* Adjust this value to your preferred width */
}
.filter-input {
  width: 100%; /* Adjust this value to your desired width */
}
.property-column {
  width: 170px; /* Adjust this value to your preferred width */
}

.quantity-column {
  width: 50px; /* Adjust this value to your preferred width */
}
.price-column {
  width: 50px; /* Adjust this value to your preferred width */
}

.seller-column {
  width: 70px;
}

