.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    height: 800px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-family: Courier New, monospace;
    position: relative;
  }
  
  .chat-messages {
    flex: 1;
    padding: 9px;
    padding-top: 20px; /* Add this line */
    overflow-y: auto;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .message-time {
    color: #777;
  }
  
  .message-username {
    font-weight: bold;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  .chat-input input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
    font-family: Courier New, monospace;
  }
  
  .chat-input button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-family: Courier New, monospace;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .toggle-minimize {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  
  .minimized {
    width: 200px;
    height: 30px;
    border: none;
    overflow: hidden;
    text-align: center;
  }