.details-view-container {
    width: 50%;
    height: calc(100vh - 100px); /* height of the header */
    position: sticky;
    top: 100px; /* height of the header */
    overflow-y: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
  
  .details-view-content {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 1rem;
    margin-top: 1rem;
  }
  .details-view-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .details-view-table th {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .details-view-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .details-view-table tr.even {
    background-color: #f2f2f2;
  }
  
  .details-view-table tr.odd {
    background-color: #fff;
  }