header {
  background-color: black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: 0;
  z-index: 1;
}

header h1 {
  display: inline;
  color: white;
  margin: 0;
  font-size: 2.5em;
  margin-bottom: 0.5rem;
}

header h1 .orange {
  color: orange;
}

header p {
  color: white;
  margin: 0;
}

.header-info {
  font-size: 1.1rem;
  color: white;
}

.sound-toggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transform: scale(3);  
  margin-right: 40px;
  margin-top: 20px;
}

.offer-box {
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.offer-text {
  margin-left: 10px;
}

.large-emoji {
  font-size: 3rem;
}

.large-text {
  font-size: 3rem;
}

.offer-text a {
  color: orange;
}

footer {
  background-color: black;
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

footer a {
  color: orange;
}

.table-header {
  borderBottom: solid 3px black;
  background: aliceblue;
  color: black;
  fontWeight: bold;
}

.table-cell {
  padding: 10px;
  borderBottom: solid 1px gray;
  background: papayawhip;
}

.table-container {
  width: 50%;
  overflow-y: scroll;
}


.content-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.offers-table {
  width: 50%;
}