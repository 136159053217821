/* Header.css */

.view-options {
    display: flex;
    gap: 1rem;
  }
  
  .live-market-btn,
  .market-explorer-btn,
  .all-offers-btn {
    background-color: #fff;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    transition: background-color 0.2s;
  }
  
  .live-market-btn:hover,
  .market-explorer-btn:hover {
    background-color: #f3f3f3;
  }
  
  .live-market-btn.active,
  .market-explorer-btn.active {
    background-color: #f3f3f3;
  }
  .all-offers-btn.active,
  .market-explorer-btn.active {
    background-color: #f3f3f3;
  }
  